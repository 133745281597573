/* styles_abp */
/*   ----    UTILITY    ----   */
.bar_cond {
  font-family: 'Barlow Condensed', sans-serif !important;
}

.roboto_cond {
  font-family: 'Roboto Condensed', sans-serif !important;
}

.open_sans {
  font-family: 'Open Sans', sans-serif !important;
}

.exo {
  font-family: 'Exo', sans-serif !important;
}

.text_center {
  text-align: center !important;
}

.text_left {
  text-align: left !important;
}

.text_right {
  text-align: right !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.text_trasform_none {
  text-transform: unset !important;
}

input[type="text"],
input[type="password"],
textarea,
select {
  background-color: rgba(255, 255, 255, 0);
  outline: none !important;
  background-image: none;
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0) inset;
          box-shadow: 0 0 0 100px rgba(255, 255, 255, 0) inset;
}

input[type="text"]:focus, input[type="text"]:active, input[type="text"]:-webkit-autofill,
input[type="password"]:focus,
input[type="password"]:active,
input[type="password"]:-webkit-autofill,
textarea:focus,
textarea:active,
textarea:-webkit-autofill,
select:focus,
select:active,
select:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0);
  outline: none !important;
  background-image: none;
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0) inset;
}

.pagebuilder_placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 200%;
  color: rgba(115, 135, 156, 0.8);
  font-size: 24px;
  font-weight: 300;
}

.content_placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  color: rgba(115, 135, 156, 0.8);
  font-size: 24px;
  font-weight: 300;
}

.placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px 20px;
  font-size: 21px;
  line-height: 24px;
  font-weight: 300;
  color: #8fa4b8;
}

button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.form_subtitle {
  width: 100%;
  padding: 25px 3px 5px 3px;
  text-align: right;
  border-bottom: 1px solid rgba(115, 135, 156, 0.2);
}

.sticky_top_0_z10 {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 0;
}

.justify_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.align_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.flex_column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex_grow_1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.flex_direction_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex_direction_col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex_justify_start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.flex_justify_end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.flex_justify_center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.flex_justify_space_around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.flex_justify_space-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.left_it {
  margin-right: auto !important;
}

.right_it {
  margin-left: auto !important;
}

/* animations */
@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation: flash 3s infinite;
          animation: flash 3s infinite;
}

@-webkit-keyframes flash_bg_red {
  from,
  50%,
  to {
    background-color: rgba(202, 5, 5, 0.1);
  }
  25%,
  75% {
    background-color: rgba(202, 5, 5, 0);
  }
}

@keyframes flash_bg_red {
  from,
  50%,
  to {
    background-color: rgba(202, 5, 5, 0.1);
  }
  25%,
  75% {
    background-color: rgba(202, 5, 5, 0);
  }
}

.flash_bg_red {
  -webkit-animation: flash_bg_red 3s infinite;
          animation: flash_bg_red 3s infinite;
}

@-webkit-keyframes flash_bg_green {
  from,
  50%,
  to {
    color: #29aa02;
    background-color: rgba(41, 170, 2, 0.1);
  }
  25%,
  75% {
    background-color: rgba(41, 170, 2, 0);
  }
}

@keyframes flash_bg_green {
  from,
  50%,
  to {
    color: #29aa02;
    background-color: rgba(41, 170, 2, 0.1);
  }
  25%,
  75% {
    background-color: rgba(41, 170, 2, 0);
  }
}

/* Jelena */
.jelena_effect::after {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  content: '';
  opacity: 0;
  pointer-events: none;
  border: 2px solid rgba(227, 84, 84, 0.6);
  -webkit-animation: anim_jelena_effect 0.9s ease-out infinite;
  animation: anim_jelena_effect 0.9s ease-out infinite;
}

@-webkit-keyframes anim_jelena_effect {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

@keyframes anim_jelena_effect {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

/* Tamara */
.tamara_effect::after {
  -webkit-box-shadow: 0 0 0 3px rgba(227, 84, 84, 0.05);
          box-shadow: 0 0 0 3px rgba(227, 84, 84, 0.05);
  -webkit-animation: anim_tamara_effect 0.5s ease-out infinite;
  animation: anim_tamara_effect 0.5s ease-out infinite;
}

@-webkit-keyframes anim_tamara_effect {
  0% {
    -webkit-box-shadow: 0 0 0 3px rgba(227, 84, 84, 0.3);
            box-shadow: 0 0 0 3px rgba(227, 84, 84, 0.3);
    opacity: 1;
    -webkit-transform: scale3d(0.6, 0.6, 1);
    transform: scale3d(0.6, 0.6, 1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 100px rgba(227, 84, 84, 0);
            box-shadow: 0 0 0 100px rgba(227, 84, 84, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes anim_tamara_effect {
  0% {
    -webkit-box-shadow: 0 0 0 3px rgba(227, 84, 84, 0.3);
            box-shadow: 0 0 0 3px rgba(227, 84, 84, 0.3);
    opacity: 1;
    -webkit-transform: scale3d(0.6, 0.6, 1);
    transform: scale3d(0.6, 0.6, 1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 100px rgba(227, 84, 84, 0);
            box-shadow: 0 0 0 100px rgba(227, 84, 84, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.flash_bg_green {
  -webkit-animation: flash_bg_green 3s infinite;
          animation: flash_bg_green 3s infinite;
}

.text_left {
  text-align: left !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.text_nowrap {
  white-space: nowrap !important;
}

.white_text {
  color: #ffffff !important;
}

.white_text_hover:hover {
  color: #ffffff !important;
}

.white_bg {
  background-color: white !important;
}

.white_bg_hover:hover {
  background-color: white !important;
}

.white_bgish {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.white_bgish_hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.white_border_top {
  border-top-color: #ffffff !important;
}

.white_border_right {
  border-right-color: #ffffff !important;
}

.white_border_bottom {
  border-bottom-color: #ffffff !important;
}

.white_border_left {
  border-left-color: #ffffff !important;
}

.white_border {
  border-color: #ffffff !important;
}

.white_borderish {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.yellow_text {
  color: #feb200 !important;
}

.yellow_text_hover:hover {
  color: #feb200 !important;
}

.yellow_bg {
  background-color: #feb200 !important;
}

.yellow_bg_hover:hover {
  background-color: #feb200 !important;
}

.yellow_bgish {
  background-color: rgba(254, 178, 0, 0.2) !important;
}

.yellow_bgish_hover:hover {
  background-color: rgba(254, 178, 0, 0.2) !important;
}

.yellow_border_top {
  border-top-color: #feb200 !important;
}

.yellow_border_right {
  border-right-color: #feb200 !important;
}

.yellow_border_bottom {
  border-bottom-color: #feb200 !important;
}

.yellow_border_left {
  border-left-color: #feb200 !important;
}

.yellow_border {
  border-color: #feb200 !important;
}

.yellow_borderish {
  border-color: rgba(254, 178, 0, 0.2) !important;
}

.orange_text {
  color: #fd9800 !important;
}

.orange_text_hover:hover {
  color: #fd9800 !important;
}

.orange_bg {
  background-color: #fd9800 !important;
}

.orange_bg_hover:hover {
  background-color: #fd9800 !important;
}

.orange_bgish {
  background-color: rgba(253, 152, 0, 0.2) !important;
}

.orange_bgish_hover:hover {
  background-color: rgba(253, 152, 0, 0.2) !important;
}

.orange_border_top {
  border-top-color: #fd9800 !important;
}

.orange_border_right {
  border-right-color: #fd9800 !important;
}

.orange_border_bottom {
  border-bottom-color: #fd9800 !important;
}

.orange_border_left {
  border-left-color: #fd9800 !important;
}

.orange_border {
  border-color: #fd9800 !important;
}

.orange_borderish {
  border-color: rgba(253, 152, 0, 0.2) !important;
}

.red_hot_text {
  color: #f30000 !important;
}

.red_hot_text_hover:hover {
  color: #f30000 !important;
}

.red_hot_bg {
  background-color: #f30000 !important;
}

.red_hot_bg_hover:hover {
  background-color: #f30000 !important;
}

.red_hot_bgish {
  background-color: rgba(243, 0, 0, 0.2) !important;
}

.red_hot_bgish_hover:hover {
  background-color: rgba(243, 0, 0, 0.2) !important;
}

.red_hot_border_top {
  border-top-color: #f30000 !important;
}

.red_hot_border_right {
  border-right-color: #f30000 !important;
}

.red_hot_border_bottom {
  border-bottom-color: #f30000 !important;
}

.red_hot_border_left {
  border-left-color: #f30000 !important;
}

.red_hot_border {
  border-color: #f30000 !important;
}

.red_hot_borderish {
  border-color: rgba(243, 0, 0, 0.2) !important;
}

.red_text {
  color: #c41821 !important;
}

.red_text_hover:hover {
  color: #c41821 !important;
}

.red_bg {
  background-color: #c41821 !important;
}

.red_bg_hover:hover {
  background-color: #c41821 !important;
}

.red_bgish {
  background-color: rgba(196, 24, 33, 0.2) !important;
}

.red_bgish_hover:hover {
  background-color: rgba(196, 24, 33, 0.2) !important;
}

.red_border_top {
  border-top-color: #c41821 !important;
}

.red_border_right {
  border-right-color: #c41821 !important;
}

.red_border_bottom {
  border-bottom-color: #c41821 !important;
}

.red_border_left {
  border-left-color: #c41821 !important;
}

.red_border {
  border-color: #c41821 !important;
}

.red_borderish {
  border-color: rgba(196, 24, 33, 0.2) !important;
}

.redish_text {
  color: #e35454 !important;
}

.redish_text_hover:hover {
  color: #e35454 !important;
}

.redish_bg {
  background-color: #e35454 !important;
}

.redish_bg_hover:hover {
  background-color: #e35454 !important;
}

.redish_bgish {
  background-color: rgba(227, 84, 84, 0.2) !important;
}

.redish_bgish_hover:hover {
  background-color: rgba(227, 84, 84, 0.2) !important;
}

.redish_border_top {
  border-top-color: #e35454 !important;
}

.redish_border_right {
  border-right-color: #e35454 !important;
}

.redish_border_bottom {
  border-bottom-color: #e35454 !important;
}

.redish_border_left {
  border-left-color: #e35454 !important;
}

.redish_border {
  border-color: #e35454 !important;
}

.redish_borderish {
  border-color: rgba(227, 84, 84, 0.2) !important;
}

.blue_text {
  color: #19a6ea !important;
}

.blue_text_hover:hover {
  color: #19a6ea !important;
}

.blue_bg {
  background-color: #19a6ea !important;
}

.blue_bg_hover:hover {
  background-color: #19a6ea !important;
}

.blue_bgish {
  background-color: rgba(25, 166, 234, 0.2) !important;
}

.blue_bgish_hover:hover {
  background-color: rgba(25, 166, 234, 0.2) !important;
}

.blue_border_top {
  border-top-color: #19a6ea !important;
}

.blue_border_right {
  border-right-color: #19a6ea !important;
}

.blue_border_bottom {
  border-bottom-color: #19a6ea !important;
}

.blue_border_left {
  border-left-color: #19a6ea !important;
}

.blue_border {
  border-color: #19a6ea !important;
}

.blue_borderish {
  border-color: rgba(25, 166, 234, 0.2) !important;
}

.blueish_text {
  color: #3fbee6 !important;
}

.blueish_text_hover:hover {
  color: #3fbee6 !important;
}

.blueish_bg {
  background-color: #3fbee6 !important;
}

.blueish_bg_hover:hover {
  background-color: #3fbee6 !important;
}

.blueish_bgish {
  background-color: rgba(63, 190, 230, 0.2) !important;
}

.blueish_bgish_hover:hover {
  background-color: rgba(63, 190, 230, 0.2) !important;
}

.blueish_border_top {
  border-top-color: #3fbee6 !important;
}

.blueish_border_right {
  border-right-color: #3fbee6 !important;
}

.blueish_border_bottom {
  border-bottom-color: #3fbee6 !important;
}

.blueish_border_left {
  border-left-color: #3fbee6 !important;
}

.blueish_border {
  border-color: #3fbee6 !important;
}

.blueish_borderish {
  border-color: rgba(63, 190, 230, 0.2) !important;
}

.green_01_text {
  color: #27AE60 !important;
}

.green_01_text_hover:hover {
  color: #27AE60 !important;
}

.green_01_bg {
  background-color: #27ae60 !important;
}

.green_01_bg_hover:hover {
  background-color: #27ae60 !important;
}

.green_01_bgish {
  background-color: rgba(39, 174, 96, 0.2) !important;
}

.green_01_bgish_hover:hover {
  background-color: rgba(39, 174, 96, 0.2) !important;
}

.green_01_border_top {
  border-top-color: #27AE60 !important;
}

.green_01_border_right {
  border-right-color: #27AE60 !important;
}

.green_01_border_bottom {
  border-bottom-color: #27AE60 !important;
}

.green_01_border_left {
  border-left-color: #27AE60 !important;
}

.green_01_border {
  border-color: #27AE60 !important;
}

.green_01_borderish {
  border-color: rgba(39, 174, 96, 0.2) !important;
}

.green_02_text {
  color: #02904d !important;
}

.green_02_text_hover:hover {
  color: #02904d !important;
}

.green_02_bg {
  background-color: #02904d !important;
}

.green_02_bg_hover:hover {
  background-color: #02904d !important;
}

.green_02_bgish {
  background-color: rgba(2, 144, 77, 0.2) !important;
}

.green_02_bgish_hover:hover {
  background-color: rgba(2, 144, 77, 0.2) !important;
}

.green_02_border_top {
  border-top-color: #02904d !important;
}

.green_02_border_right {
  border-right-color: #02904d !important;
}

.green_02_border_bottom {
  border-bottom-color: #02904d !important;
}

.green_02_border_left {
  border-left-color: #02904d !important;
}

.green_02_border {
  border-color: #02904d !important;
}

.green_02_borderish {
  border-color: rgba(2, 144, 77, 0.2) !important;
}

.green_03_text {
  color: #02542a !important;
}

.green_03_text_hover:hover {
  color: #02542a !important;
}

.green_03_bg {
  background-color: #02542a !important;
}

.green_03_bg_hover:hover {
  background-color: #02542a !important;
}

.green_03_bgish {
  background-color: rgba(2, 84, 42, 0.2) !important;
}

.green_03_bgish_hover:hover {
  background-color: rgba(2, 84, 42, 0.2) !important;
}

.green_03_border_top {
  border-top-color: #02542a !important;
}

.green_03_border_right {
  border-right-color: #02542a !important;
}

.green_03_border_bottom {
  border-bottom-color: #02542a !important;
}

.green_03_border_left {
  border-left-color: #02542a !important;
}

.green_03_border {
  border-color: #02542a !important;
}

.green_03_borderish {
  border-color: rgba(2, 84, 42, 0.2) !important;
}

.green_text {
  color: #5b9602 !important;
}

.green_text_hover:hover {
  color: #5b9602 !important;
}

.green_bg {
  background-color: #5b9602 !important;
}

.green_bg_hover:hover {
  background-color: #5b9602 !important;
}

.green_bgish {
  background-color: rgba(91, 150, 2, 0.2) !important;
}

.green_bgish_hover:hover {
  background-color: rgba(91, 150, 2, 0.2) !important;
}

.green_border_top {
  border-top-color: #5b9602 !important;
}

.green_border_right {
  border-right-color: #5b9602 !important;
}

.green_border_bottom {
  border-bottom-color: #5b9602 !important;
}

.green_border_left {
  border-left-color: #5b9602 !important;
}

.green_border {
  border-color: #5b9602 !important;
}

.green_borderish {
  border-color: rgba(91, 150, 2, 0.2) !important;
}

.dark_green_text {
  color: #7fbd22 !important;
}

.dark_green_text_hover:hover {
  color: #7fbd22 !important;
}

.dark_green_bg {
  background-color: #7fbd22 !important;
}

.dark_green_bg_hover:hover {
  background-color: #7fbd22 !important;
}

.dark_green_bgish {
  background-color: rgba(127, 189, 34, 0.2) !important;
}

.dark_green_bgish_hover:hover {
  background-color: rgba(127, 189, 34, 0.2) !important;
}

.dark_green_border_top {
  border-top-color: #7fbd22 !important;
}

.dark_green_border_right {
  border-right-color: #7fbd22 !important;
}

.dark_green_border_bottom {
  border-bottom-color: #7fbd22 !important;
}

.dark_green_border_left {
  border-left-color: #7fbd22 !important;
}

.dark_green_border {
  border-color: #7fbd22 !important;
}

.dark_green_borderish {
  border-color: rgba(127, 189, 34, 0.2) !important;
}

.gray_text {
  color: #8da2b6 !important;
}

.gray_text_hover:hover {
  color: #8da2b6 !important;
}

.gray_bg {
  background-color: #8da2b6 !important;
}

.gray_bg_hover:hover {
  background-color: #8da2b6 !important;
}

.gray_bgish {
  background-color: rgba(141, 162, 182, 0.2) !important;
}

.gray_bgish_hover:hover {
  background-color: rgba(141, 162, 182, 0.2) !important;
}

.gray_border_top {
  border-top-color: #8da2b6 !important;
}

.gray_border_right {
  border-right-color: #8da2b6 !important;
}

.gray_border_bottom {
  border-bottom-color: #8da2b6 !important;
}

.gray_border_left {
  border-left-color: #8da2b6 !important;
}

.gray_border {
  border-color: #8da2b6 !important;
}

.gray_borderish {
  border-color: rgba(141, 162, 182, 0.2) !important;
}

.gray_dark_text {
  color: #657688 !important;
}

.gray_dark_text_hover:hover {
  color: #657688 !important;
}

.gray_dark_bg {
  background-color: #657688 !important;
}

.gray_dark_bg_hover:hover {
  background-color: #657688 !important;
}

.gray_dark_bgish {
  background-color: rgba(101, 118, 136, 0.2) !important;
}

.gray_dark_bgish_hover:hover {
  background-color: rgba(101, 118, 136, 0.2) !important;
}

.gray_dark_border_top {
  border-top-color: #657688 !important;
}

.gray_dark_border_right {
  border-right-color: #657688 !important;
}

.gray_dark_border_bottom {
  border-bottom-color: #657688 !important;
}

.gray_dark_border_left {
  border-left-color: #657688 !important;
}

.gray_dark_border {
  border-color: #657688 !important;
}

.gray_dark_borderish {
  border-color: rgba(101, 118, 136, 0.2) !important;
}

.gray_darken_text {
  color: #556373 !important;
}

.gray_darken_text_hover:hover {
  color: #556373 !important;
}

.gray_darken_bg {
  background-color: #556373 !important;
}

.gray_darken_bg_hover:hover {
  background-color: #556373 !important;
}

.gray_darken_bgish {
  background-color: rgba(85, 99, 115, 0.2) !important;
}

.gray_darken_bgish_hover:hover {
  background-color: rgba(85, 99, 115, 0.2) !important;
}

.gray_darken_border_top {
  border-top-color: #556373 !important;
}

.gray_darken_border_right {
  border-right-color: #556373 !important;
}

.gray_darken_border_bottom {
  border-bottom-color: #556373 !important;
}

.gray_darken_border_left {
  border-left-color: #556373 !important;
}

.gray_darken_border {
  border-color: #556373 !important;
}

.gray_darken_borderish {
  border-color: rgba(85, 99, 115, 0.2) !important;
}

.col_border_right {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(141, 162, 182, 0.3);
}

.col_border_left {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(141, 162, 182, 0.3);
}

.col_border_top {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(141, 162, 182, 0.3);
}

.col_border_bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(141, 162, 182, 0.3);
}

.no_border {
  border: none !important;
}

.z_0 {
  z-index: 0 !important;
}

.z_1 {
  z-index: 1 !important;
}

.z_2 {
  z-index: 2 !important;
}

.z_3 {
  z-index: 3 !important;
}

.z_4 {
  z-index: 4 !important;
}

.z_5 {
  z-index: 5 !important;
}

.z_6 {
  z-index: 6 !important;
}

.z_7 {
  z-index: 7 !important;
}

.z_8 {
  z-index: 8 !important;
}

.z_9 {
  z-index: 9 !important;
}

.z_10 {
  z-index: 10 !important;
}

.z_11 {
  z-index: 11 !important;
}

.z_12 {
  z-index: 12 !important;
}

.z_13 {
  z-index: 13 !important;
}

.z_14 {
  z-index: 14 !important;
}

.z_15 {
  z-index: 15 !important;
}

.z_16 {
  z-index: 16 !important;
}

.z_17 {
  z-index: 17 !important;
}

.z_18 {
  z-index: 18 !important;
}

.z_19 {
  z-index: 19 !important;
}

.z_20 {
  z-index: 20 !important;
}

.z_21 {
  z-index: 21 !important;
}

.z_22 {
  z-index: 22 !important;
}

.z_23 {
  z-index: 23 !important;
}

.z_24 {
  z-index: 24 !important;
}

.z_25 {
  z-index: 25 !important;
}

.z_26 {
  z-index: 26 !important;
}

.z_27 {
  z-index: 27 !important;
}

.z_28 {
  z-index: 28 !important;
}

.z_29 {
  z-index: 29 !important;
}

.z_30 {
  z-index: 30 !important;
}

.z_31 {
  z-index: 31 !important;
}

.z_32 {
  z-index: 32 !important;
}

.z_33 {
  z-index: 33 !important;
}

.z_34 {
  z-index: 34 !important;
}

.z_35 {
  z-index: 35 !important;
}

.z_36 {
  z-index: 36 !important;
}

.z_37 {
  z-index: 37 !important;
}

.z_38 {
  z-index: 38 !important;
}

.z_39 {
  z-index: 39 !important;
}

.z_40 {
  z-index: 40 !important;
}

.z_41 {
  z-index: 41 !important;
}

.z_42 {
  z-index: 42 !important;
}

.z_43 {
  z-index: 43 !important;
}

.z_44 {
  z-index: 44 !important;
}

.z_45 {
  z-index: 45 !important;
}

.z_46 {
  z-index: 46 !important;
}

.z_47 {
  z-index: 47 !important;
}

.z_48 {
  z-index: 48 !important;
}

.z_49 {
  z-index: 49 !important;
}

.z_50 {
  z-index: 50 !important;
}

.z_51 {
  z-index: 51 !important;
}

.z_52 {
  z-index: 52 !important;
}

.z_53 {
  z-index: 53 !important;
}

.z_54 {
  z-index: 54 !important;
}

.z_55 {
  z-index: 55 !important;
}

.z_56 {
  z-index: 56 !important;
}

.z_57 {
  z-index: 57 !important;
}

.z_58 {
  z-index: 58 !important;
}

.z_59 {
  z-index: 59 !important;
}

.z_60 {
  z-index: 60 !important;
}

.z_61 {
  z-index: 61 !important;
}

.z_62 {
  z-index: 62 !important;
}

.z_63 {
  z-index: 63 !important;
}

.z_64 {
  z-index: 64 !important;
}

.z_65 {
  z-index: 65 !important;
}

.z_66 {
  z-index: 66 !important;
}

.z_67 {
  z-index: 67 !important;
}

.z_68 {
  z-index: 68 !important;
}

.z_69 {
  z-index: 69 !important;
}

.z_70 {
  z-index: 70 !important;
}

.z_71 {
  z-index: 71 !important;
}

.z_72 {
  z-index: 72 !important;
}

.z_73 {
  z-index: 73 !important;
}

.z_74 {
  z-index: 74 !important;
}

.z_75 {
  z-index: 75 !important;
}

.z_76 {
  z-index: 76 !important;
}

.z_77 {
  z-index: 77 !important;
}

.z_78 {
  z-index: 78 !important;
}

.z_79 {
  z-index: 79 !important;
}

.z_80 {
  z-index: 80 !important;
}

.z_81 {
  z-index: 81 !important;
}

.z_82 {
  z-index: 82 !important;
}

.z_83 {
  z-index: 83 !important;
}

.z_84 {
  z-index: 84 !important;
}

.z_85 {
  z-index: 85 !important;
}

.z_86 {
  z-index: 86 !important;
}

.z_87 {
  z-index: 87 !important;
}

.z_88 {
  z-index: 88 !important;
}

.z_89 {
  z-index: 89 !important;
}

.z_90 {
  z-index: 90 !important;
}

.z_91 {
  z-index: 91 !important;
}

.z_92 {
  z-index: 92 !important;
}

.z_93 {
  z-index: 93 !important;
}

.z_94 {
  z-index: 94 !important;
}

.z_95 {
  z-index: 95 !important;
}

.z_96 {
  z-index: 96 !important;
}

.z_97 {
  z-index: 97 !important;
}

.z_98 {
  z-index: 98 !important;
}

.z_99 {
  z-index: 99 !important;
}

.z_100 {
  z-index: 100 !important;
}

.z_101 {
  z-index: 101 !important;
}

.z_102 {
  z-index: 102 !important;
}

.z_103 {
  z-index: 103 !important;
}

.z_104 {
  z-index: 104 !important;
}

.z_105 {
  z-index: 105 !important;
}

.z_106 {
  z-index: 106 !important;
}

.z_107 {
  z-index: 107 !important;
}

.z_108 {
  z-index: 108 !important;
}

.z_109 {
  z-index: 109 !important;
}

.z_110 {
  z-index: 110 !important;
}

.z_111 {
  z-index: 111 !important;
}

.z_112 {
  z-index: 112 !important;
}

.z_113 {
  z-index: 113 !important;
}

.z_114 {
  z-index: 114 !important;
}

.z_115 {
  z-index: 115 !important;
}

.z_116 {
  z-index: 116 !important;
}

.z_117 {
  z-index: 117 !important;
}

.z_118 {
  z-index: 118 !important;
}

.z_119 {
  z-index: 119 !important;
}

.z_120 {
  z-index: 120 !important;
}

.z_121 {
  z-index: 121 !important;
}

.z_122 {
  z-index: 122 !important;
}

.z_123 {
  z-index: 123 !important;
}

.z_124 {
  z-index: 124 !important;
}

.z_125 {
  z-index: 125 !important;
}

.z_126 {
  z-index: 126 !important;
}

.z_127 {
  z-index: 127 !important;
}

.z_128 {
  z-index: 128 !important;
}

.z_129 {
  z-index: 129 !important;
}

.z_130 {
  z-index: 130 !important;
}

.z_131 {
  z-index: 131 !important;
}

.z_132 {
  z-index: 132 !important;
}

.z_133 {
  z-index: 133 !important;
}

.z_134 {
  z-index: 134 !important;
}

.z_135 {
  z-index: 135 !important;
}

.z_136 {
  z-index: 136 !important;
}

.z_137 {
  z-index: 137 !important;
}

.z_138 {
  z-index: 138 !important;
}

.z_139 {
  z-index: 139 !important;
}

.z_140 {
  z-index: 140 !important;
}

.z_141 {
  z-index: 141 !important;
}

.z_142 {
  z-index: 142 !important;
}

.z_143 {
  z-index: 143 !important;
}

.z_144 {
  z-index: 144 !important;
}

.z_145 {
  z-index: 145 !important;
}

.z_146 {
  z-index: 146 !important;
}

.z_147 {
  z-index: 147 !important;
}

.z_148 {
  z-index: 148 !important;
}

.z_149 {
  z-index: 149 !important;
}

.z_150 {
  z-index: 150 !important;
}

.z_151 {
  z-index: 151 !important;
}

.z_152 {
  z-index: 152 !important;
}

.z_153 {
  z-index: 153 !important;
}

.z_154 {
  z-index: 154 !important;
}

.z_155 {
  z-index: 155 !important;
}

.z_156 {
  z-index: 156 !important;
}

.z_157 {
  z-index: 157 !important;
}

.z_158 {
  z-index: 158 !important;
}

.z_159 {
  z-index: 159 !important;
}

.z_160 {
  z-index: 160 !important;
}

.z_161 {
  z-index: 161 !important;
}

.z_162 {
  z-index: 162 !important;
}

.z_163 {
  z-index: 163 !important;
}

.z_164 {
  z-index: 164 !important;
}

.z_165 {
  z-index: 165 !important;
}

.z_166 {
  z-index: 166 !important;
}

.z_167 {
  z-index: 167 !important;
}

.z_168 {
  z-index: 168 !important;
}

.z_169 {
  z-index: 169 !important;
}

.z_170 {
  z-index: 170 !important;
}

.z_171 {
  z-index: 171 !important;
}

.z_172 {
  z-index: 172 !important;
}

.z_173 {
  z-index: 173 !important;
}

.z_174 {
  z-index: 174 !important;
}

.z_175 {
  z-index: 175 !important;
}

.z_176 {
  z-index: 176 !important;
}

.z_177 {
  z-index: 177 !important;
}

.z_178 {
  z-index: 178 !important;
}

.z_179 {
  z-index: 179 !important;
}

.z_180 {
  z-index: 180 !important;
}

.z_181 {
  z-index: 181 !important;
}

.z_182 {
  z-index: 182 !important;
}

.z_183 {
  z-index: 183 !important;
}

.z_184 {
  z-index: 184 !important;
}

.z_185 {
  z-index: 185 !important;
}

.z_186 {
  z-index: 186 !important;
}

.z_187 {
  z-index: 187 !important;
}

.z_188 {
  z-index: 188 !important;
}

.z_189 {
  z-index: 189 !important;
}

.z_190 {
  z-index: 190 !important;
}

.z_191 {
  z-index: 191 !important;
}

.z_192 {
  z-index: 192 !important;
}

.z_193 {
  z-index: 193 !important;
}

.z_194 {
  z-index: 194 !important;
}

.z_195 {
  z-index: 195 !important;
}

.z_196 {
  z-index: 196 !important;
}

.z_197 {
  z-index: 197 !important;
}

.z_198 {
  z-index: 198 !important;
}

.z_199 {
  z-index: 199 !important;
}

.z_200 {
  z-index: 200 !important;
}

.font_200 {
  font-weight: 200 !important;
}

.font_300 {
  font-weight: 300 !important;
}

.font_400 {
  font-weight: 400 !important;
}

.font_500 {
  font-weight: 500 !important;
}

.font_100 {
  font-weight: 100 !important;
}

.font_600 {
  font-weight: 600 !important;
}

.font_700 {
  font-weight: 700 !important;
}

.font_800 {
  font-weight: 800 !important;
}

.font_900 {
  font-weight: 900 !important;
}

.font_10px {
  font-size: 10px !important;
}

.font_11px {
  font-size: 11px !important;
}

.font_12px {
  font-size: 12px !important;
}

.font_13px {
  font-size: 13px !important;
}

.font_14px {
  font-size: 14px !important;
}

.font_15px {
  font-size: 15px !important;
}

.font_16px {
  font-size: 16px !important;
}

.font_17px {
  font-size: 17px !important;
}

.font_18px {
  font-size: 18px !important;
}

.font_19px {
  font-size: 19px !important;
}

.font_20px {
  font-size: 20px !important;
}

.font_21px {
  font-size: 21px !important;
}

.font_22px {
  font-size: 22px !important;
}

.font_23px {
  font-size: 23px !important;
}

.font_24px {
  font-size: 24px !important;
}

.font_32px {
  font-size: 32px !important;
}

.font_34px {
  font-size: 34px !important;
}

.font_36px {
  font-size: 36px !important;
}

.font_40px {
  font-size: 40px !important;
}

.svg_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
}

i.status_icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin: 0 20px;
}

i.status_icon::after {
  position: absolute;
  z-index: 0;
  top: 11px;
  left: 11px;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: rgba(141, 162, 182, 0.14);
  content: '';
  -webkit-box-shadow: 0 0 0 20px rgba(141, 162, 182, 0.14);
          box-shadow: 0 0 0 20px rgba(141, 162, 182, 0.14);
}

i.status_icon.transparent {
  color: #556373;
}

i.status_icon.transparent::after {
  background-color: rgba(141, 162, 182, 0);
  -webkit-box-shadow: 0 0 0 0px rgba(141, 162, 182, 0);
          box-shadow: 0 0 0 0px rgba(141, 162, 182, 0);
}

i.status_icon.red {
  color: #ff0000;
}

i.status_icon.red::after {
  background-color: rgba(255, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 0 20px rgba(255, 0, 0, 0.1);
          box-shadow: 0 0 0 20px rgba(255, 0, 0, 0.1);
}

i.status_icon.redish {
  color: #e35454;
}

i.status_icon.redish::after {
  background-color: rgba(227, 84, 84, 0.14);
  -webkit-box-shadow: 0 0 0 20px rgba(227, 84, 84, 0.14);
          box-shadow: 0 0 0 20px rgba(227, 84, 84, 0.14);
}

i.status_icon.orange {
  color: #fd9800;
}

i.status_icon.orange::after {
  background-color: rgba(253, 152, 0, 0.2);
  -webkit-box-shadow: 0 0 0 20px rgba(253, 152, 0, 0.2);
          box-shadow: 0 0 0 20px rgba(253, 152, 0, 0.2);
}

i.status_icon.yellow {
  color: #feb200;
}

i.status_icon.yellow::after {
  background-color: rgba(254, 178, 0, 0.2);
  -webkit-box-shadow: 0 0 0 20px rgba(254, 178, 0, 0.2);
          box-shadow: 0 0 0 20px rgba(254, 178, 0, 0.2);
}

i.status_icon.green {
  color: #5b9602;
}

i.status_icon.green::after {
  background-color: rgba(127, 189, 34, 0.2);
  -webkit-box-shadow: 0 0 0 20px rgba(127, 189, 34, 0.2);
          box-shadow: 0 0 0 20px rgba(127, 189, 34, 0.2);
}

i.status_icon.blue {
  color: #19a6ea;
}

i.status_icon.blue::after {
  background-color: rgba(25, 166, 234, 0.14);
  -webkit-box-shadow: 0 0 0 20px rgba(25, 166, 234, 0.14);
          box-shadow: 0 0 0 20px rgba(25, 166, 234, 0.14);
}

.padding_left_0 {
  padding-left: 0px !important;
}

.padding_right_0 {
  padding-right: 0px !important;
}

.padding_top_0 {
  padding-top: 0px !important;
}

.padding_bottom_0 {
  padding-bottom: 0px !important;
}

.padding_0 {
  padding: 0px !important;
}

.margin_left_0 {
  margin-left: 0px !important;
}

.margin_right_0 {
  margin-right: 0px !important;
}

.margin_top_0 {
  margin-top: 0px !important;
}

.margin_bottom_0 {
  margin-bottom: 0px !important;
}

.margin_0 {
  margin: 0px !important;
}

.padding_left_1 {
  padding-left: 1px !important;
}

.padding_right_1 {
  padding-right: 1px !important;
}

.padding_top_1 {
  padding-top: 1px !important;
}

.padding_bottom_1 {
  padding-bottom: 1px !important;
}

.padding_1 {
  padding: 1px !important;
}

.margin_left_1 {
  margin-left: 1px !important;
}

.margin_right_1 {
  margin-right: 1px !important;
}

.margin_top_1 {
  margin-top: 1px !important;
}

.margin_bottom_1 {
  margin-bottom: 1px !important;
}

.margin_1 {
  margin: 1px !important;
}

.padding_left_2 {
  padding-left: 2px !important;
}

.padding_right_2 {
  padding-right: 2px !important;
}

.padding_top_2 {
  padding-top: 2px !important;
}

.padding_bottom_2 {
  padding-bottom: 2px !important;
}

.padding_2 {
  padding: 2px !important;
}

.margin_left_2 {
  margin-left: 2px !important;
}

.margin_right_2 {
  margin-right: 2px !important;
}

.margin_top_2 {
  margin-top: 2px !important;
}

.margin_bottom_2 {
  margin-bottom: 2px !important;
}

.margin_2 {
  margin: 2px !important;
}

.padding_left_3 {
  padding-left: 3px !important;
}

.padding_right_3 {
  padding-right: 3px !important;
}

.padding_top_3 {
  padding-top: 3px !important;
}

.padding_bottom_3 {
  padding-bottom: 3px !important;
}

.padding_3 {
  padding: 3px !important;
}

.margin_left_3 {
  margin-left: 3px !important;
}

.margin_right_3 {
  margin-right: 3px !important;
}

.margin_top_3 {
  margin-top: 3px !important;
}

.margin_bottom_3 {
  margin-bottom: 3px !important;
}

.margin_3 {
  margin: 3px !important;
}

.padding_left_4 {
  padding-left: 4px !important;
}

.padding_right_4 {
  padding-right: 4px !important;
}

.padding_top_4 {
  padding-top: 4px !important;
}

.padding_bottom_4 {
  padding-bottom: 4px !important;
}

.padding_4 {
  padding: 4px !important;
}

.margin_left_4 {
  margin-left: 4px !important;
}

.margin_right_4 {
  margin-right: 4px !important;
}

.margin_top_4 {
  margin-top: 4px !important;
}

.margin_bottom_4 {
  margin-bottom: 4px !important;
}

.margin_4 {
  margin: 4px !important;
}

.padding_left_5 {
  padding-left: 5px !important;
}

.padding_right_5 {
  padding-right: 5px !important;
}

.padding_top_5 {
  padding-top: 5px !important;
}

.padding_bottom_5 {
  padding-bottom: 5px !important;
}

.padding_5 {
  padding: 5px !important;
}

.margin_left_5 {
  margin-left: 5px !important;
}

.margin_right_5 {
  margin-right: 5px !important;
}

.margin_top_5 {
  margin-top: 5px !important;
}

.margin_bottom_5 {
  margin-bottom: 5px !important;
}

.margin_5 {
  margin: 5px !important;
}

.padding_left_6 {
  padding-left: 6px !important;
}

.padding_right_6 {
  padding-right: 6px !important;
}

.padding_top_6 {
  padding-top: 6px !important;
}

.padding_bottom_6 {
  padding-bottom: 6px !important;
}

.padding_6 {
  padding: 6px !important;
}

.margin_left_6 {
  margin-left: 6px !important;
}

.margin_right_6 {
  margin-right: 6px !important;
}

.margin_top_6 {
  margin-top: 6px !important;
}

.margin_bottom_6 {
  margin-bottom: 6px !important;
}

.margin_6 {
  margin: 6px !important;
}

.padding_left_7 {
  padding-left: 7px !important;
}

.padding_right_7 {
  padding-right: 7px !important;
}

.padding_top_7 {
  padding-top: 7px !important;
}

.padding_bottom_7 {
  padding-bottom: 7px !important;
}

.padding_7 {
  padding: 7px !important;
}

.margin_left_7 {
  margin-left: 7px !important;
}

.margin_right_7 {
  margin-right: 7px !important;
}

.margin_top_7 {
  margin-top: 7px !important;
}

.margin_bottom_7 {
  margin-bottom: 7px !important;
}

.margin_7 {
  margin: 7px !important;
}

.padding_left_8 {
  padding-left: 8px !important;
}

.padding_right_8 {
  padding-right: 8px !important;
}

.padding_top_8 {
  padding-top: 8px !important;
}

.padding_bottom_8 {
  padding-bottom: 8px !important;
}

.padding_8 {
  padding: 8px !important;
}

.margin_left_8 {
  margin-left: 8px !important;
}

.margin_right_8 {
  margin-right: 8px !important;
}

.margin_top_8 {
  margin-top: 8px !important;
}

.margin_bottom_8 {
  margin-bottom: 8px !important;
}

.margin_8 {
  margin: 8px !important;
}

.padding_left_9 {
  padding-left: 9px !important;
}

.padding_right_9 {
  padding-right: 9px !important;
}

.padding_top_9 {
  padding-top: 9px !important;
}

.padding_bottom_9 {
  padding-bottom: 9px !important;
}

.padding_9 {
  padding: 9px !important;
}

.margin_left_9 {
  margin-left: 9px !important;
}

.margin_right_9 {
  margin-right: 9px !important;
}

.margin_top_9 {
  margin-top: 9px !important;
}

.margin_bottom_9 {
  margin-bottom: 9px !important;
}

.margin_9 {
  margin: 9px !important;
}

.padding_left_10 {
  padding-left: 10px !important;
}

.padding_right_10 {
  padding-right: 10px !important;
}

.padding_top_10 {
  padding-top: 10px !important;
}

.padding_bottom_10 {
  padding-bottom: 10px !important;
}

.padding_10 {
  padding: 10px !important;
}

.margin_left_10 {
  margin-left: 10px !important;
}

.margin_right_10 {
  margin-right: 10px !important;
}

.margin_top_10 {
  margin-top: 10px !important;
}

.margin_bottom_10 {
  margin-bottom: 10px !important;
}

.margin_10 {
  margin: 10px !important;
}

.padding_left_11 {
  padding-left: 11px !important;
}

.padding_right_11 {
  padding-right: 11px !important;
}

.padding_top_11 {
  padding-top: 11px !important;
}

.padding_bottom_11 {
  padding-bottom: 11px !important;
}

.padding_11 {
  padding: 11px !important;
}

.margin_left_11 {
  margin-left: 11px !important;
}

.margin_right_11 {
  margin-right: 11px !important;
}

.margin_top_11 {
  margin-top: 11px !important;
}

.margin_bottom_11 {
  margin-bottom: 11px !important;
}

.margin_11 {
  margin: 11px !important;
}

.padding_left_12 {
  padding-left: 12px !important;
}

.padding_right_12 {
  padding-right: 12px !important;
}

.padding_top_12 {
  padding-top: 12px !important;
}

.padding_bottom_12 {
  padding-bottom: 12px !important;
}

.padding_12 {
  padding: 12px !important;
}

.margin_left_12 {
  margin-left: 12px !important;
}

.margin_right_12 {
  margin-right: 12px !important;
}

.margin_top_12 {
  margin-top: 12px !important;
}

.margin_bottom_12 {
  margin-bottom: 12px !important;
}

.margin_12 {
  margin: 12px !important;
}

.padding_left_13 {
  padding-left: 13px !important;
}

.padding_right_13 {
  padding-right: 13px !important;
}

.padding_top_13 {
  padding-top: 13px !important;
}

.padding_bottom_13 {
  padding-bottom: 13px !important;
}

.padding_13 {
  padding: 13px !important;
}

.margin_left_13 {
  margin-left: 13px !important;
}

.margin_right_13 {
  margin-right: 13px !important;
}

.margin_top_13 {
  margin-top: 13px !important;
}

.margin_bottom_13 {
  margin-bottom: 13px !important;
}

.margin_13 {
  margin: 13px !important;
}

.padding_left_14 {
  padding-left: 14px !important;
}

.padding_right_14 {
  padding-right: 14px !important;
}

.padding_top_14 {
  padding-top: 14px !important;
}

.padding_bottom_14 {
  padding-bottom: 14px !important;
}

.padding_14 {
  padding: 14px !important;
}

.margin_left_14 {
  margin-left: 14px !important;
}

.margin_right_14 {
  margin-right: 14px !important;
}

.margin_top_14 {
  margin-top: 14px !important;
}

.margin_bottom_14 {
  margin-bottom: 14px !important;
}

.margin_14 {
  margin: 14px !important;
}

.padding_left_15 {
  padding-left: 15px !important;
}

.padding_right_15 {
  padding-right: 15px !important;
}

.padding_top_15 {
  padding-top: 15px !important;
}

.padding_bottom_15 {
  padding-bottom: 15px !important;
}

.padding_15 {
  padding: 15px !important;
}

.margin_left_15 {
  margin-left: 15px !important;
}

.margin_right_15 {
  margin-right: 15px !important;
}

.margin_top_15 {
  margin-top: 15px !important;
}

.margin_bottom_15 {
  margin-bottom: 15px !important;
}

.margin_15 {
  margin: 15px !important;
}

.padding_left_16 {
  padding-left: 16px !important;
}

.padding_right_16 {
  padding-right: 16px !important;
}

.padding_top_16 {
  padding-top: 16px !important;
}

.padding_bottom_16 {
  padding-bottom: 16px !important;
}

.padding_16 {
  padding: 16px !important;
}

.margin_left_16 {
  margin-left: 16px !important;
}

.margin_right_16 {
  margin-right: 16px !important;
}

.margin_top_16 {
  margin-top: 16px !important;
}

.margin_bottom_16 {
  margin-bottom: 16px !important;
}

.margin_16 {
  margin: 16px !important;
}

.padding_left_17 {
  padding-left: 17px !important;
}

.padding_right_17 {
  padding-right: 17px !important;
}

.padding_top_17 {
  padding-top: 17px !important;
}

.padding_bottom_17 {
  padding-bottom: 17px !important;
}

.padding_17 {
  padding: 17px !important;
}

.margin_left_17 {
  margin-left: 17px !important;
}

.margin_right_17 {
  margin-right: 17px !important;
}

.margin_top_17 {
  margin-top: 17px !important;
}

.margin_bottom_17 {
  margin-bottom: 17px !important;
}

.margin_17 {
  margin: 17px !important;
}

.padding_left_18 {
  padding-left: 18px !important;
}

.padding_right_18 {
  padding-right: 18px !important;
}

.padding_top_18 {
  padding-top: 18px !important;
}

.padding_bottom_18 {
  padding-bottom: 18px !important;
}

.padding_18 {
  padding: 18px !important;
}

.margin_left_18 {
  margin-left: 18px !important;
}

.margin_right_18 {
  margin-right: 18px !important;
}

.margin_top_18 {
  margin-top: 18px !important;
}

.margin_bottom_18 {
  margin-bottom: 18px !important;
}

.margin_18 {
  margin: 18px !important;
}

.padding_left_19 {
  padding-left: 19px !important;
}

.padding_right_19 {
  padding-right: 19px !important;
}

.padding_top_19 {
  padding-top: 19px !important;
}

.padding_bottom_19 {
  padding-bottom: 19px !important;
}

.padding_19 {
  padding: 19px !important;
}

.margin_left_19 {
  margin-left: 19px !important;
}

.margin_right_19 {
  margin-right: 19px !important;
}

.margin_top_19 {
  margin-top: 19px !important;
}

.margin_bottom_19 {
  margin-bottom: 19px !important;
}

.margin_19 {
  margin: 19px !important;
}

.padding_left_20 {
  padding-left: 20px !important;
}

.padding_right_20 {
  padding-right: 20px !important;
}

.padding_top_20 {
  padding-top: 20px !important;
}

.padding_bottom_20 {
  padding-bottom: 20px !important;
}

.padding_20 {
  padding: 20px !important;
}

.margin_left_20 {
  margin-left: 20px !important;
}

.margin_right_20 {
  margin-right: 20px !important;
}

.margin_top_20 {
  margin-top: 20px !important;
}

.margin_bottom_20 {
  margin-bottom: 20px !important;
}

.margin_20 {
  margin: 20px !important;
}

.padding_left_21 {
  padding-left: 21px !important;
}

.padding_right_21 {
  padding-right: 21px !important;
}

.padding_top_21 {
  padding-top: 21px !important;
}

.padding_bottom_21 {
  padding-bottom: 21px !important;
}

.padding_21 {
  padding: 21px !important;
}

.margin_left_21 {
  margin-left: 21px !important;
}

.margin_right_21 {
  margin-right: 21px !important;
}

.margin_top_21 {
  margin-top: 21px !important;
}

.margin_bottom_21 {
  margin-bottom: 21px !important;
}

.margin_21 {
  margin: 21px !important;
}

.padding_left_22 {
  padding-left: 22px !important;
}

.padding_right_22 {
  padding-right: 22px !important;
}

.padding_top_22 {
  padding-top: 22px !important;
}

.padding_bottom_22 {
  padding-bottom: 22px !important;
}

.padding_22 {
  padding: 22px !important;
}

.margin_left_22 {
  margin-left: 22px !important;
}

.margin_right_22 {
  margin-right: 22px !important;
}

.margin_top_22 {
  margin-top: 22px !important;
}

.margin_bottom_22 {
  margin-bottom: 22px !important;
}

.margin_22 {
  margin: 22px !important;
}

.padding_left_23 {
  padding-left: 23px !important;
}

.padding_right_23 {
  padding-right: 23px !important;
}

.padding_top_23 {
  padding-top: 23px !important;
}

.padding_bottom_23 {
  padding-bottom: 23px !important;
}

.padding_23 {
  padding: 23px !important;
}

.margin_left_23 {
  margin-left: 23px !important;
}

.margin_right_23 {
  margin-right: 23px !important;
}

.margin_top_23 {
  margin-top: 23px !important;
}

.margin_bottom_23 {
  margin-bottom: 23px !important;
}

.margin_23 {
  margin: 23px !important;
}

.padding_left_24 {
  padding-left: 24px !important;
}

.padding_right_24 {
  padding-right: 24px !important;
}

.padding_top_24 {
  padding-top: 24px !important;
}

.padding_bottom_24 {
  padding-bottom: 24px !important;
}

.padding_24 {
  padding: 24px !important;
}

.margin_left_24 {
  margin-left: 24px !important;
}

.margin_right_24 {
  margin-right: 24px !important;
}

.margin_top_24 {
  margin-top: 24px !important;
}

.margin_bottom_24 {
  margin-bottom: 24px !important;
}

.margin_24 {
  margin: 24px !important;
}

.padding_left_25 {
  padding-left: 25px !important;
}

.padding_right_25 {
  padding-right: 25px !important;
}

.padding_top_25 {
  padding-top: 25px !important;
}

.padding_bottom_25 {
  padding-bottom: 25px !important;
}

.padding_25 {
  padding: 25px !important;
}

.margin_left_25 {
  margin-left: 25px !important;
}

.margin_right_25 {
  margin-right: 25px !important;
}

.margin_top_25 {
  margin-top: 25px !important;
}

.margin_bottom_25 {
  margin-bottom: 25px !important;
}

.margin_25 {
  margin: 25px !important;
}

.padding_left_26 {
  padding-left: 26px !important;
}

.padding_right_26 {
  padding-right: 26px !important;
}

.padding_top_26 {
  padding-top: 26px !important;
}

.padding_bottom_26 {
  padding-bottom: 26px !important;
}

.padding_26 {
  padding: 26px !important;
}

.margin_left_26 {
  margin-left: 26px !important;
}

.margin_right_26 {
  margin-right: 26px !important;
}

.margin_top_26 {
  margin-top: 26px !important;
}

.margin_bottom_26 {
  margin-bottom: 26px !important;
}

.margin_26 {
  margin: 26px !important;
}

.padding_left_27 {
  padding-left: 27px !important;
}

.padding_right_27 {
  padding-right: 27px !important;
}

.padding_top_27 {
  padding-top: 27px !important;
}

.padding_bottom_27 {
  padding-bottom: 27px !important;
}

.padding_27 {
  padding: 27px !important;
}

.margin_left_27 {
  margin-left: 27px !important;
}

.margin_right_27 {
  margin-right: 27px !important;
}

.margin_top_27 {
  margin-top: 27px !important;
}

.margin_bottom_27 {
  margin-bottom: 27px !important;
}

.margin_27 {
  margin: 27px !important;
}

.padding_left_28 {
  padding-left: 28px !important;
}

.padding_right_28 {
  padding-right: 28px !important;
}

.padding_top_28 {
  padding-top: 28px !important;
}

.padding_bottom_28 {
  padding-bottom: 28px !important;
}

.padding_28 {
  padding: 28px !important;
}

.margin_left_28 {
  margin-left: 28px !important;
}

.margin_right_28 {
  margin-right: 28px !important;
}

.margin_top_28 {
  margin-top: 28px !important;
}

.margin_bottom_28 {
  margin-bottom: 28px !important;
}

.margin_28 {
  margin: 28px !important;
}

.padding_left_29 {
  padding-left: 29px !important;
}

.padding_right_29 {
  padding-right: 29px !important;
}

.padding_top_29 {
  padding-top: 29px !important;
}

.padding_bottom_29 {
  padding-bottom: 29px !important;
}

.padding_29 {
  padding: 29px !important;
}

.margin_left_29 {
  margin-left: 29px !important;
}

.margin_right_29 {
  margin-right: 29px !important;
}

.margin_top_29 {
  margin-top: 29px !important;
}

.margin_bottom_29 {
  margin-bottom: 29px !important;
}

.margin_29 {
  margin: 29px !important;
}

.padding_left_30 {
  padding-left: 30px !important;
}

.padding_right_30 {
  padding-right: 30px !important;
}

.padding_top_30 {
  padding-top: 30px !important;
}

.padding_bottom_30 {
  padding-bottom: 30px !important;
}

.padding_30 {
  padding: 30px !important;
}

.margin_left_30 {
  margin-left: 30px !important;
}

.margin_right_30 {
  margin-right: 30px !important;
}

.margin_top_30 {
  margin-top: 30px !important;
}

.margin_bottom_30 {
  margin-bottom: 30px !important;
}

.margin_30 {
  margin: 30px !important;
}

.padding_left_0 {
  padding-left: 0px !important;
}

.padding_right_0 {
  padding-right: 0px !important;
}

.padding_top_0 {
  padding-top: 0px !important;
}

.padding_bottom_0 {
  padding-bottom: 0px !important;
}

.padding_0 {
  padding: 0px !important;
}

.margin_left_0 {
  margin-left: 0px !important;
}

.margin_right_0 {
  margin-right: 0px !important;
}

.margin_top_0 {
  margin-top: 0px !important;
}

.margin_bottom_0 {
  margin-bottom: 0px !important;
}

.margin_0 {
  margin: 0px !important;
}

.padding_left_5 {
  padding-left: 5px !important;
}

.padding_right_5 {
  padding-right: 5px !important;
}

.padding_top_5 {
  padding-top: 5px !important;
}

.padding_bottom_5 {
  padding-bottom: 5px !important;
}

.padding_5 {
  padding: 5px !important;
}

.margin_left_5 {
  margin-left: 5px !important;
}

.margin_right_5 {
  margin-right: 5px !important;
}

.margin_top_5 {
  margin-top: 5px !important;
}

.margin_bottom_5 {
  margin-bottom: 5px !important;
}

.margin_5 {
  margin: 5px !important;
}

.padding_left_10 {
  padding-left: 10px !important;
}

.padding_right_10 {
  padding-right: 10px !important;
}

.padding_top_10 {
  padding-top: 10px !important;
}

.padding_bottom_10 {
  padding-bottom: 10px !important;
}

.padding_10 {
  padding: 10px !important;
}

.margin_left_10 {
  margin-left: 10px !important;
}

.margin_right_10 {
  margin-right: 10px !important;
}

.margin_top_10 {
  margin-top: 10px !important;
}

.margin_bottom_10 {
  margin-bottom: 10px !important;
}

.margin_10 {
  margin: 10px !important;
}

.padding_left_15 {
  padding-left: 15px !important;
}

.padding_right_15 {
  padding-right: 15px !important;
}

.padding_top_15 {
  padding-top: 15px !important;
}

.padding_bottom_15 {
  padding-bottom: 15px !important;
}

.padding_15 {
  padding: 15px !important;
}

.margin_left_15 {
  margin-left: 15px !important;
}

.margin_right_15 {
  margin-right: 15px !important;
}

.margin_top_15 {
  margin-top: 15px !important;
}

.margin_bottom_15 {
  margin-bottom: 15px !important;
}

.margin_15 {
  margin: 15px !important;
}

.padding_left_20 {
  padding-left: 20px !important;
}

.padding_right_20 {
  padding-right: 20px !important;
}

.padding_top_20 {
  padding-top: 20px !important;
}

.padding_bottom_20 {
  padding-bottom: 20px !important;
}

.padding_20 {
  padding: 20px !important;
}

.margin_left_20 {
  margin-left: 20px !important;
}

.margin_right_20 {
  margin-right: 20px !important;
}

.margin_top_20 {
  margin-top: 20px !important;
}

.margin_bottom_20 {
  margin-bottom: 20px !important;
}

.margin_20 {
  margin: 20px !important;
}

.padding_left_25 {
  padding-left: 25px !important;
}

.padding_right_25 {
  padding-right: 25px !important;
}

.padding_top_25 {
  padding-top: 25px !important;
}

.padding_bottom_25 {
  padding-bottom: 25px !important;
}

.padding_25 {
  padding: 25px !important;
}

.margin_left_25 {
  margin-left: 25px !important;
}

.margin_right_25 {
  margin-right: 25px !important;
}

.margin_top_25 {
  margin-top: 25px !important;
}

.margin_bottom_25 {
  margin-bottom: 25px !important;
}

.margin_25 {
  margin: 25px !important;
}

.padding_left_30 {
  padding-left: 30px !important;
}

.padding_right_30 {
  padding-right: 30px !important;
}

.padding_top_30 {
  padding-top: 30px !important;
}

.padding_bottom_30 {
  padding-bottom: 30px !important;
}

.padding_30 {
  padding: 30px !important;
}

.margin_left_30 {
  margin-left: 30px !important;
}

.margin_right_30 {
  margin-right: 30px !important;
}

.margin_top_30 {
  margin-top: 30px !important;
}

.margin_bottom_30 {
  margin-bottom: 30px !important;
}

.margin_30 {
  margin: 30px !important;
}

.padding_left_35 {
  padding-left: 35px !important;
}

.padding_right_35 {
  padding-right: 35px !important;
}

.padding_top_35 {
  padding-top: 35px !important;
}

.padding_bottom_35 {
  padding-bottom: 35px !important;
}

.padding_35 {
  padding: 35px !important;
}

.margin_left_35 {
  margin-left: 35px !important;
}

.margin_right_35 {
  margin-right: 35px !important;
}

.margin_top_35 {
  margin-top: 35px !important;
}

.margin_bottom_35 {
  margin-bottom: 35px !important;
}

.margin_35 {
  margin: 35px !important;
}

.padding_left_40 {
  padding-left: 40px !important;
}

.padding_right_40 {
  padding-right: 40px !important;
}

.padding_top_40 {
  padding-top: 40px !important;
}

.padding_bottom_40 {
  padding-bottom: 40px !important;
}

.padding_40 {
  padding: 40px !important;
}

.margin_left_40 {
  margin-left: 40px !important;
}

.margin_right_40 {
  margin-right: 40px !important;
}

.margin_top_40 {
  margin-top: 40px !important;
}

.margin_bottom_40 {
  margin-bottom: 40px !important;
}

.margin_40 {
  margin: 40px !important;
}

.padding_left_45 {
  padding-left: 45px !important;
}

.padding_right_45 {
  padding-right: 45px !important;
}

.padding_top_45 {
  padding-top: 45px !important;
}

.padding_bottom_45 {
  padding-bottom: 45px !important;
}

.padding_45 {
  padding: 45px !important;
}

.margin_left_45 {
  margin-left: 45px !important;
}

.margin_right_45 {
  margin-right: 45px !important;
}

.margin_top_45 {
  margin-top: 45px !important;
}

.margin_bottom_45 {
  margin-bottom: 45px !important;
}

.margin_45 {
  margin: 45px !important;
}

.padding_left_50 {
  padding-left: 50px !important;
}

.padding_right_50 {
  padding-right: 50px !important;
}

.padding_top_50 {
  padding-top: 50px !important;
}

.padding_bottom_50 {
  padding-bottom: 50px !important;
}

.padding_50 {
  padding: 50px !important;
}

.margin_left_50 {
  margin-left: 50px !important;
}

.margin_right_50 {
  margin-right: 50px !important;
}

.margin_top_50 {
  margin-top: 50px !important;
}

.margin_bottom_50 {
  margin-bottom: 50px !important;
}

.margin_50 {
  margin: 50px !important;
}

.padding_left_55 {
  padding-left: 55px !important;
}

.padding_right_55 {
  padding-right: 55px !important;
}

.padding_top_55 {
  padding-top: 55px !important;
}

.padding_bottom_55 {
  padding-bottom: 55px !important;
}

.padding_55 {
  padding: 55px !important;
}

.margin_left_55 {
  margin-left: 55px !important;
}

.margin_right_55 {
  margin-right: 55px !important;
}

.margin_top_55 {
  margin-top: 55px !important;
}

.margin_bottom_55 {
  margin-bottom: 55px !important;
}

.margin_55 {
  margin: 55px !important;
}

.padding_left_60 {
  padding-left: 60px !important;
}

.padding_right_60 {
  padding-right: 60px !important;
}

.padding_top_60 {
  padding-top: 60px !important;
}

.padding_bottom_60 {
  padding-bottom: 60px !important;
}

.padding_60 {
  padding: 60px !important;
}

.margin_left_60 {
  margin-left: 60px !important;
}

.margin_right_60 {
  margin-right: 60px !important;
}

.margin_top_60 {
  margin-top: 60px !important;
}

.margin_bottom_60 {
  margin-bottom: 60px !important;
}

.margin_60 {
  margin: 60px !important;
}

.padding_left_65 {
  padding-left: 65px !important;
}

.padding_right_65 {
  padding-right: 65px !important;
}

.padding_top_65 {
  padding-top: 65px !important;
}

.padding_bottom_65 {
  padding-bottom: 65px !important;
}

.padding_65 {
  padding: 65px !important;
}

.margin_left_65 {
  margin-left: 65px !important;
}

.margin_right_65 {
  margin-right: 65px !important;
}

.margin_top_65 {
  margin-top: 65px !important;
}

.margin_bottom_65 {
  margin-bottom: 65px !important;
}

.margin_65 {
  margin: 65px !important;
}

.padding_left_70 {
  padding-left: 70px !important;
}

.padding_right_70 {
  padding-right: 70px !important;
}

.padding_top_70 {
  padding-top: 70px !important;
}

.padding_bottom_70 {
  padding-bottom: 70px !important;
}

.padding_70 {
  padding: 70px !important;
}

.margin_left_70 {
  margin-left: 70px !important;
}

.margin_right_70 {
  margin-right: 70px !important;
}

.margin_top_70 {
  margin-top: 70px !important;
}

.margin_bottom_70 {
  margin-bottom: 70px !important;
}

.margin_70 {
  margin: 70px !important;
}

.padding_left_75 {
  padding-left: 75px !important;
}

.padding_right_75 {
  padding-right: 75px !important;
}

.padding_top_75 {
  padding-top: 75px !important;
}

.padding_bottom_75 {
  padding-bottom: 75px !important;
}

.padding_75 {
  padding: 75px !important;
}

.margin_left_75 {
  margin-left: 75px !important;
}

.margin_right_75 {
  margin-right: 75px !important;
}

.margin_top_75 {
  margin-top: 75px !important;
}

.margin_bottom_75 {
  margin-bottom: 75px !important;
}

.margin_75 {
  margin: 75px !important;
}

.padding_left_80 {
  padding-left: 80px !important;
}

.padding_right_80 {
  padding-right: 80px !important;
}

.padding_top_80 {
  padding-top: 80px !important;
}

.padding_bottom_80 {
  padding-bottom: 80px !important;
}

.padding_80 {
  padding: 80px !important;
}

.margin_left_80 {
  margin-left: 80px !important;
}

.margin_right_80 {
  margin-right: 80px !important;
}

.margin_top_80 {
  margin-top: 80px !important;
}

.margin_bottom_80 {
  margin-bottom: 80px !important;
}

.margin_80 {
  margin: 80px !important;
}

.padding_left_85 {
  padding-left: 85px !important;
}

.padding_right_85 {
  padding-right: 85px !important;
}

.padding_top_85 {
  padding-top: 85px !important;
}

.padding_bottom_85 {
  padding-bottom: 85px !important;
}

.padding_85 {
  padding: 85px !important;
}

.margin_left_85 {
  margin-left: 85px !important;
}

.margin_right_85 {
  margin-right: 85px !important;
}

.margin_top_85 {
  margin-top: 85px !important;
}

.margin_bottom_85 {
  margin-bottom: 85px !important;
}

.margin_85 {
  margin: 85px !important;
}

.padding_left_90 {
  padding-left: 90px !important;
}

.padding_right_90 {
  padding-right: 90px !important;
}

.padding_top_90 {
  padding-top: 90px !important;
}

.padding_bottom_90 {
  padding-bottom: 90px !important;
}

.padding_90 {
  padding: 90px !important;
}

.margin_left_90 {
  margin-left: 90px !important;
}

.margin_right_90 {
  margin-right: 90px !important;
}

.margin_top_90 {
  margin-top: 90px !important;
}

.margin_bottom_90 {
  margin-bottom: 90px !important;
}

.margin_90 {
  margin: 90px !important;
}

.padding_left_95 {
  padding-left: 95px !important;
}

.padding_right_95 {
  padding-right: 95px !important;
}

.padding_top_95 {
  padding-top: 95px !important;
}

.padding_bottom_95 {
  padding-bottom: 95px !important;
}

.padding_95 {
  padding: 95px !important;
}

.margin_left_95 {
  margin-left: 95px !important;
}

.margin_right_95 {
  margin-right: 95px !important;
}

.margin_top_95 {
  margin-top: 95px !important;
}

.margin_bottom_95 {
  margin-bottom: 95px !important;
}

.margin_95 {
  margin: 95px !important;
}

.padding_left_100 {
  padding-left: 100px !important;
}

.padding_right_100 {
  padding-right: 100px !important;
}

.padding_top_100 {
  padding-top: 100px !important;
}

.padding_bottom_100 {
  padding-bottom: 100px !important;
}

.padding_100 {
  padding: 100px !important;
}

.margin_left_100 {
  margin-left: 100px !important;
}

.margin_right_100 {
  margin-right: 100px !important;
}

.margin_top_100 {
  margin-top: 100px !important;
}

.margin_bottom_100 {
  margin-bottom: 100px !important;
}

.margin_100 {
  margin: 100px !important;
}

.padding_left_105 {
  padding-left: 105px !important;
}

.padding_right_105 {
  padding-right: 105px !important;
}

.padding_top_105 {
  padding-top: 105px !important;
}

.padding_bottom_105 {
  padding-bottom: 105px !important;
}

.padding_105 {
  padding: 105px !important;
}

.margin_left_105 {
  margin-left: 105px !important;
}

.margin_right_105 {
  margin-right: 105px !important;
}

.margin_top_105 {
  margin-top: 105px !important;
}

.margin_bottom_105 {
  margin-bottom: 105px !important;
}

.margin_105 {
  margin: 105px !important;
}

.padding_left_110 {
  padding-left: 110px !important;
}

.padding_right_110 {
  padding-right: 110px !important;
}

.padding_top_110 {
  padding-top: 110px !important;
}

.padding_bottom_110 {
  padding-bottom: 110px !important;
}

.padding_110 {
  padding: 110px !important;
}

.margin_left_110 {
  margin-left: 110px !important;
}

.margin_right_110 {
  margin-right: 110px !important;
}

.margin_top_110 {
  margin-top: 110px !important;
}

.margin_bottom_110 {
  margin-bottom: 110px !important;
}

.margin_110 {
  margin: 110px !important;
}

.padding_left_115 {
  padding-left: 115px !important;
}

.padding_right_115 {
  padding-right: 115px !important;
}

.padding_top_115 {
  padding-top: 115px !important;
}

.padding_bottom_115 {
  padding-bottom: 115px !important;
}

.padding_115 {
  padding: 115px !important;
}

.margin_left_115 {
  margin-left: 115px !important;
}

.margin_right_115 {
  margin-right: 115px !important;
}

.margin_top_115 {
  margin-top: 115px !important;
}

.margin_bottom_115 {
  margin-bottom: 115px !important;
}

.margin_115 {
  margin: 115px !important;
}

.padding_left_120 {
  padding-left: 120px !important;
}

.padding_right_120 {
  padding-right: 120px !important;
}

.padding_top_120 {
  padding-top: 120px !important;
}

.padding_bottom_120 {
  padding-bottom: 120px !important;
}

.padding_120 {
  padding: 120px !important;
}

.margin_left_120 {
  margin-left: 120px !important;
}

.margin_right_120 {
  margin-right: 120px !important;
}

.margin_top_120 {
  margin-top: 120px !important;
}

.margin_bottom_120 {
  margin-bottom: 120px !important;
}

.margin_120 {
  margin: 120px !important;
}

.margin_left_auto {
  margin-left: auto !important;
}

.margin_right_auto {
  margin-right: auto !important;
}

.margin_top_auto {
  margin-top: auto !important;
}

.margin_bottom_auto {
  margin-bottom: auto !important;
}

.left_0 {
  left: 0;
}

.right_0 {
  right: 0;
}

.top_0 {
  top: 0;
}

.bottom_0 {
  bottom: 0;
}

.disabled {
  opacity: .4 !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  pointer-events: none !important;
}

.full_w {
  width: 100% !important;
}

.quazi_match_tracker {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 80px;
}

.quazi_match_tracker .full_time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: absolute;
  z-index: 0;
  top: 32px;
  left: 0;
  width: 100%;
  height: 16px;
}

.quazi_match_tracker .full_time .line {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: rgba(141, 162, 182, 0.2);
}

.quazi_match_tracker .full_time .time_elapsed_line {
  position: absolute;
  z-index: 2;
  height: 2px;
  border-radius: 2px;
  background-color: rgba(141, 162, 182, 0.6);
}

.quazi_match_tracker .full_time .time_elapsed {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 40px;
  height: 16px;
  color: #8da2b6;
  font-size: 11px;
  font-weight: 400;
  border-left: 1px solid rgba(141, 162, 182, 0.3);
  background-color: #f6f8f9;
}

.quazi_match_tracker .full_time .overtime {
  position: absolute;
  z-index: 2;
  height: 2px;
  border-radius: 2px;
  background-color: #e35454;
}

.quazi_match_tracker .teamname {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 21px;
  padding: 0 8px;
  font-size: 11px;
  text-transform: uppercase;
  color: rgba(141, 162, 182, 0.6);
}

.quazi_match_tracker .point_in_time {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 44px;
  border-right: 1px solid rgba(141, 162, 182, 0.4);
  pointer-events: none;
}

.quazi_match_tracker .point_in_time .point {
  position: relative;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  border: 2px solid #afbdcb;
  background-color: #f6f8f9;
}

.quazi_match_tracker .point_in_time i.event_icon,
.quazi_match_tracker .point_in_time .svg_icon {
  position: absolute;
  z-index: 5;
  right: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: #8da2b6;
  background-color: #f6f8f9;
  pointer-events: all;
}

.quazi_match_tracker .point_in_time.score {
  border-right: 1px solid rgba(63, 190, 230, 0.6);
}

.quazi_match_tracker .point_in_time.score .point {
  border: 2px solid #3fbee6;
  background-color: #3fbee6;
}

.quazi_match_tracker .point_in_time.score i.event_icon,
.quazi_match_tracker .point_in_time.score .svg_icon {
  color: #3fbee6;
}

.quazi_match_tracker .point_in_time.score i.event_icon .tooltip_content,
.quazi_match_tracker .point_in_time.score .svg_icon .tooltip_content {
  background-color: #3fbee6;
}

.quazi_match_tracker .point_in_time.carton .carton_icon {
  position: absolute;
  z-index: 5;
  right: -7px;
  width: 14px;
  height: 18px;
  border: 2px solid #f6f8f9;
  background-color: rgba(141, 162, 182, 0.4);
  pointer-events: all;
}

.quazi_match_tracker .point_in_time.carton.yellow .carton_icon {
  background-color: #feb200;
}

.quazi_match_tracker .point_in_time.carton.yellow .carton_icon .tooltip_content {
  background-color: #feb200;
}

.quazi_match_tracker .point_in_time.carton.red .carton_icon {
  background-color: #e35454;
}

.quazi_match_tracker .point_in_time.carton.red .carton_icon .tooltip_content {
  background-color: #e35454;
}

.quazi_match_tracker .home {
  position: absolute;
  z-index: 1;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  height: 45px;
  width: 100%;
}

.quazi_match_tracker .home .teamname {
  margin-bottom: 6px;
}

.quazi_match_tracker .home .point_in_time {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.quazi_match_tracker .home .point_in_time i.event_icon,
.quazi_match_tracker .home .point_in_time .svg_icon {
  bottom: auto;
  top: 0;
}

.quazi_match_tracker .home .point_in_time.carton .carton_icon {
  top: 0;
  bottom: auto;
}

.quazi_match_tracker .away {
  position: absolute;
  z-index: 2;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 44px;
  width: 100%;
}

.quazi_match_tracker .away .teamname {
  margin-top: 6px;
}

.quazi_match_tracker .away .point_in_time {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.quazi_match_tracker .away .point_in_time i.event_icon,
.quazi_match_tracker .away .point_in_time .svg_icon {
  bottom: 0;
  top: auto;
}

.quazi_match_tracker .away .point_in_time.carton .carton_icon {
  top: auto;
  bottom: 0;
}

.resulting_form .drop_menu {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.resulting_form .input_form_group.edit_result {
  border: 1px solid rgba(143, 164, 184, 0.4);
}

.resulting_form .input_form_group:hover {
  border: 2px solid #19a6ea;
}

.resulting_form .input_form_group:focus-within {
  z-index: 100;
  background-color: #fff;
  border: 2px solid rgba(25, 166, 234, 0.8);
  -webkit-box-shadow: 0 0 20px 2px rgba(25, 166, 234, 0.4);
          box-shadow: 0 0 20px 2px rgba(25, 166, 234, 0.4);
}

.resulting_form .form_row {
  padding: 4px 0;
}

.resulting_form .form_row .input_label {
  font-size: 11px;
  line-height: 12px;
}

.resulting_form .form_row .btn {
  height: 32px;
  padding: 0 8px;
  margin: 0 0 0 3px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 4px;
}

.title_info_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: auto;
  padding: 40px 0;
  border-bottom: 1px solid #e2e8ed;
}

.title_info_block .info_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 1400px;
}

@media (max-width: 1440px) {
  .title_info_block .info_wrapper {
    width: 100%;
  }
}

.title_info_block .info_wrapper .info_col {
  border-right: 1px solid rgba(141, 162, 182, 0.2);
}

.title_info_block .info_wrapper .info_col:first-child {
  border-left: 1px solid rgba(141, 162, 182, 0.2);
}

.title_info_block .info_wrapper .general_info {
  width: 260px;
}

.title_info_block .info_wrapper .balance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  width: calc(100% - 520px);
}

.title_info_block .info_wrapper .balance .info_content {
  width: auto;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0 10px 0 0;
}

.title_info_block .info_wrapper .balance .amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.title_info_block .info_wrapper .balance .amount .info_row {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.title_info_block .info_wrapper .info_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%;
  height: 26px;
  padding: 0 10px 0 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-transform: uppercase;
}

.title_info_block .info_wrapper .title_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 26px;
  min-width: 70px;
}

.title_info_block .info_wrapper .title_sub_label {
  width: auto;
  padding: 0 10px;
}

.title_info_block .info_wrapper .info_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.title_info_block .info_wrapper .info_label,
.title_info_block .info_wrapper .info_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 26px;
}

.title_info_block .info_wrapper .info_label {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 70px;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: right;
  text-transform: uppercase;
  color: #8da2b6;
}

.title_info_block .info_wrapper .info_content {
  width: 190px;
  padding: 0 0 0 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  color: #556373;
}

.title_info_block .info_wrapper .first_name,
.title_info_block .info_wrapper .last_name,
.title_info_block .info_wrapper .city,
.title_info_block .info_wrapper .country {
  padding-right: 6px;
}

.title_info_block .info_wrapper .date,
.title_info_block .info_wrapper .time {
  height: 16px;
  line-height: 16px;
}

.title_info_block .info_wrapper .date {
  padding: 0 10px 0 0;
  margin: 0 10px 0 0;
  border-right: 1px solid rgba(141, 162, 182, 0.6);
}

.timeline {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.timeline .timeline_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.timeline .timeline_item .time_point,
.timeline .timeline_item .date,
.timeline .timeline_item .time,
.timeline .timeline_item .person,
.timeline .timeline_item .action,
.timeline .timeline_item .state,
.timeline .timeline_item .description,
.timeline .timeline_item .action_col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 42px;
  font-size: 13px;
  font-weight: 400;
}

.timeline .timeline_item .time_point {
  position: relative;
  width: 32px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.timeline .timeline_item .time_point .point {
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background-color: #6E7884;
}

.timeline .timeline_item .time_point::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  width: 2px;
  height: 13px;
  background-color: rgba(110, 120, 132, 0.2);
}

.timeline .timeline_item .time_point::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 2px;
  height: 13px;
  background-color: rgba(110, 120, 132, 0.2);
}

.timeline .timeline_item .date {
  width: 80px;
  height: 13px;
  padding: 0 4px 0 4px;
  border-right: 1px solid rgba(110, 120, 132, 0.2);
}

.timeline .timeline_item .time {
  width: 60px;
  height: 13px;
  padding: 0 20px 0 4px;
}

.timeline .timeline_item .person {
  width: 200px;
  padding: 0 20px 0 0;
}

.timeline .timeline_item .action {
  width: 168px;
  padding: 0 20px 0 0;
}

.timeline .timeline_item .state {
  width: 140px;
  padding: 0 20px 0 0;
}

.timeline .timeline_item .description {
  width: 320px;
}

.timeline .timeline_item .action_col {
  width: 80px;
}

.timeline .timeline_item:first-child .time_point::before {
  opacity: 0;
}

.timeline .timeline_item:first-child .time_point::after {
  opacity: 1;
}

.timeline .timeline_item:last-child .time_point::before {
  opacity: 1;
}

.timeline .timeline_item:last-child .time_point::after {
  opacity: 0;
}

.timeline .timeline_item:only-child .time_point::before {
  opacity: 0;
}

.timeline .timeline_item:only-child .time_point::after {
  opacity: 0;
}
